import axios from 'axios';
import { createRouter, createWebHistory } from 'vue-router';

const routes  =  [
    {
      path: '/',
      component: () => import('@/views/TicketLink.vue'),
      props: (route) => ({ ticketId: route.query.ticketId, custName:route.query.custName }),
    },
    {
      path: '/otp',
      component: () => import('@/views/OTP.vue'),
      props: (route) => ({ ticketId: route.query.ticketId, custName:route.query.custName }),

    },
    {
      path: '/ticket',
      component: () => import('@/views/Ticket.vue'),
    },
    {
      path: '/closed-ticket',
      component: () => import('@/views/ClosedTicket.vue'),
    },
    {
      path: '/error',
      component: () => import('@/views/Error.vue'),
    },
  ];

  const router = createRouter({
    history: createWebHistory(),
    routes,
  });
  
// Route for the ticket link
// router.beforeEach(async (to, from, next) => {
//   if (to.path === '/') {
//     try {
//       const ticketId = to.query.ticketId;
//       const ticketStatus = await getTicketStatus(ticketId);

//       if (ticketStatus === 'closed') {
//         next('/closed-ticket');
//       } else {
//         next('/otp');
//       }
//     } catch (error) {
//       // Handle error
//       next('/error');
//     }
//   } else {
//     next();
//   }
// });

// Route for the OTP page
// router.beforeEach(async (to, from, next) => {
//   if (to.path === '/ticket') {
//     try {
//       const otp = to.query.otp;
//       // const ticketData = await getTicketData(otp);
//       // console.log("ticketData - ", ticketData);
//       if (ticketData.SendType === 1) {
//         next('/ticket');
//       } else {
//         next('/ticket-with-signature');
//       }
//     } catch (error) {
//       // Handle error
//       next('/error');
//     }
//   } else {
//     next();
//   }
// });

// Helper function to get the ticket status from the API
// async function getTicketStatus(ticketId) {
//   try {
//     const response = await axios.get(`/api/getTicketStatus?ticketId=${ticketId}`);
//     return response.data.status;
//   } catch (error) {
//     throw new Error('Failed to get ticket status');
//   }
// }

// Helper function to get the ticket data from the API
async function getTicketData(otp) {
  try {
    const response = await axios.get(`/api/getTicketData?otp=${otp}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to get ticket data');
  }
}

export function setupRouter(app) {
  const router = createRouter({
    history: createWebHistory(),
    routes,
  });
  app.use(router);
  return router;
}
export default router;