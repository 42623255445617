import { createApp } from 'vue';
import App from './App.vue';
import { setupStore } from './store';
import { setupRouter } from './router';
import { registerServiceWorker } from './registerServiceWorker';


async function bootstrap() {
  const app = createApp(App);

  // Setup store
  setupStore(app);

  // Setup router
  await setupRouter(app);

  // Register Service Worker
  registerServiceWorker();

  // Mount the app
  app.mount('#app');
}

bootstrap().catch((error) => {
  console.error('Failed to bootstrap the application:', error);
});
