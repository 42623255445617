<template>
  <div id="nav">
    <router-view />
    <div>
      <a target="_blank" style="text-decoration: none" href="https://retailsoft.io/"
        ><img
          id="mb-logo"
          style="background-color: transparent; max-width: 100px"
          src="./assets/mb-logo.png"
      /></a>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {},
};
</script>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
  font-family: "Heebo", sans-serif;
  background-color: white;
}
:root {
  --text-color: #2c3e50;
  --link-color: #42b983;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--text-color);
}

.ticket-page {
  direction: ltr;
  justify-content: center;
  align-items: center;
}

#nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 95vh;
}

#logo-container {
  display: flex;
  justify-content: center;
}

#mb-logo {
  width: 20%;
  height: auto;
}
.icon-button {
  border: none;
  background-color: transparent;
}

.fa-comment-dots,
.fa-solid {
  color: rgb(32, 89, 253); /* Replace 'red' with the color you want */
  font-size: 2.5vh;
}
.otp-input {
  width: 16vh;
  text-align: center;
  border: none;
  border-bottom: 2px solid #ececec;
  font-size: 4vh; /* Replace '2vh' with the size you want */
  font-weight: bold;
}

/* Hide the number input spin buttons */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>

