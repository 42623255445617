import { createStore } from 'vuex'

export function setupStore(app) {

  const store = createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
app.use(store);
  return store;
}